import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Badge, Button, Container, Row, Col } from 'reactstrap'
import { slugify } from '../util/utilityFunctions'
import Jumbotron from '../components/Jumbotron'


const tagsPage = ({ pageContext }) => {
	const { tags, tagPostCounts } = pageContext; // descturinc
	return (
		<Layout pageTitle="App tags">
			<SEO 
			  title="Kumpulan Label" 
			  keywords={['tags, topics']} 
		      lang="id"
		      img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2Fkisspng-documentation-organization-logo-service-forms-5b112049d36aa6.562040021527849033866.png?alt=media&token=5c95988e-6aa7-414e-bf99-e8bde2d0a340"
		      description="Label postingan Waingapu Open Mind"      
			/>
		    <Jumbotron title="Label Artikel Di Waingapu Open Mind" info="Kumpulan label dari artikel yang dituliskan oleh anak-anak Waingapu yang suka berbagi informasi." />
			<Container>
				<Row>
					{tags.map(tag => (
						<Col key={tag} style={{margin: '10px'}}>
							<Button style={{}} color="dark" href={`/tag/${slugify(tag)}`}>
								{tag} <Badge color="dark">{tagPostCounts[tag]}</Badge>
							</Button>
						</Col>
					))}
				</Row>
			</Container>
		</Layout>
	)
}

export default tagsPage